<template>
  <div class="chooseShop">
    <div v-if="data.length > 0">
      <h3 class="title">เลือกร้านค้า</h3>
      <div class="option">
        <div
          v-for="(d, index) in data"
          :key="index"
          data-tag="selectSeller"
          @click="setSeller(d.seller)"
        >
          {{ d.seller.code }} {{ d.seller.name }}
        </div>
      </div>
    </div>
    <div v-if="data.length === 0" class="messageStatus">
      <div v-if="loading">กำลังโหลดข้อมูล...</div>
      <div v-if="error">เกิดข้อพิดพลาด กรุณาติดต่อเจ้าหน้าที่</div>
    </div>
  </div>
</template>

<script>
import GetSellers from "@/hooks/getSellers";
import errorCode from "@/utils/errorCode";

export default {
  // props: { preUrl: { default: "/", type: String } },
  data() {
    return {
      data: [],
      error: false,
      loading: false,
      targetPath: "/",
    };
  },
  computed: {},
  methods: {
    setSeller(shop) {
      if (!window.$permission) window.$permission = {};
      window.$permission.sellerShop = shop;
      console.log("set sellerShop", window.$permission.sellerShop);

      this.continue();
    },
    continue() {
      this.$router.replace(this.targetPath);
    },
  },
  async mounted() {
    this.loading = true;
    const { body, error } = await GetSellers(1, 0);
    this.loading = false;

    if (error) {
      this.error = error;
      window.$alert(
        `${errorCode(203).message}<br>code: ${errorCode(203).code}`
      );
      console.error(error);
      return;
    }

    // get target path
    this.targetPath = this.$route.query.targetPath.startsWith("/chooseSellers")
      ? "/"
      : this.$route.query.targetPath;
    //

    // check sellers more than one
    if (body.data.length === 1) {
      this.setSeller(body.data[0].seller);
      return;
    }

    this.data = body.data;

    // get params from the target path
    const targetPathParams = new URLSearchParams(this.targetPath.split("?")[1]);

    // get seller shop by seller id from query parameters
    const sellerShop = this.data.find(
      (d) => targetPathParams.get("setSellerId") === d.seller.id.toString()
    ).seller;

    // check for auto set seller
    if (sellerShop) {
      this.setSeller(sellerShop);
    }
  },
};
</script>
<style lang="scss" scoped>
.chooseShop {
  padding: 15px;
  text-align: center;
  height: 100%;
  color: white;
  overflow: auto;
  background-image: linear-gradient(to bottom, #01592a 11%, #01974a);
  > div {
    width: 100%;
  }
}

.messageStatus {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h3 {
  margin-bottom: 30px;
}

.option {
  max-width: 400px;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  > div {
    border: 1px solid rgb(190, 190, 190);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    color: #006b35;
    background-color: #ffffff;
  }
}
</style>
